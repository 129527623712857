<template>
  <div class="addon-card">
    <img class="addon-image" :src="addonImage" alt="addon_img" />
    <div class="addon-info">
      <div class="addon-title">
        <span>{{ title }}</span>
        <v-dialog
          v-model="addonModal"
          class="mem-dialog"
          :max-width="360"
          overlay-color="#000000"
          overlay-opacity="0.54"
        >
          <template v-slot:activator="{ on, attrs }" v-if="showModalActivator">
            <span v-bind="attrs" v-on="on" class="material-icons"> help </span>
          </template>
          <div class="addon-modal">
            <span class="material-icons" @click="addonModal = false">
              close</span
            >
            <div v-html="ticket.moreInfoContents"></div>
          </div>
        </v-dialog>
      </div>
      <div class="addon-price">{{ price | currency }}</div>
    </div>

    <template>
      <template v-if="!ticket.complexQuestion">
        <div class="add-button" @click="addItem" v-if="!showSelect">
          <span class="material-icons"> add </span>
        </div>
        <div v-else class="quantity-selectors">
          <div class="selector-button" @click="ticket.selectedQuantity--">
            <span class="material-icons"> remove </span>
          </div>
          <div class="add-on-count">{{ ticket.selectedQuantity }}</div>
          <div
            class="selector-button"
            :class="{ 'is-disabled': disableIncrement }"
            @click="ticket.selectedQuantity++"
          >
            <span class="material-icons"> add </span>
          </div>
          <!-- <div class="quantity-select">
          <select v-model="ticket.selectedQuantity">
            <option
              :value="item"
              v-for="(item, index) in dropdownItems"
              :key="index"
            >
              {{ item }}
            </option>
          </select>
          <span class="select-value">{{ ticket.selectedQuantity }}</span>
          <i class="material-icons">arrow_drop_down</i>
        </div> -->
        </div>
      </template>
      <template v-else>
        <div class="dialog-activator" @click="showQuantityDialog = true">
          <div class="add-button" v-if="!showSelect">
            <span class="material-icons"> add </span>
          </div>
          <div v-else class="quantity-selectors">
            <div class="selector-button">
              <span class="material-icons"> remove </span>
            </div>
            <div>{{ ticket.selectedQuantity }}</div>
            <div class="selector-button">
              <span class="material-icons"> add </span>
            </div>
          </div>
        </div>
        <complex-question-dialog
          v-model="showQuantityDialog"
          :maxQuantity="maxQuantity"
          :question="ticket"
          @on-confirm="onDialogConfirm"
        />
      </template>
    </template>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { sumBy, slice, forEachRight, find, findLastIndex } from "lodash";
export default {
  components: {
    "complex-question-dialog": () => import("./AddonsDialog.vue"),
  },
  data: () => ({
    addonModal: false,
    showQuantityDialog: false,
  }),
  props: ["ticket", "maxQuantity", "title", "price"],
  computed: {
    ...mapGetters(["checkoutRegion"]),
    ticketPayload() {
      let { eventId, id } = this.ticket;
      return {
        ts_ticket_type_id: id,
        ts_event_id: eventId,
        ts_id: id,
        country: this.checkoutRegion,
      };
    },
    showSelect() {
      return this.ticket.selectedQuantity > 0;
    },
    // dropdownItems() {
    //   let list = [];
    //   let maxQuantity = 30;

    //   if (this.maxQuantity < 30) maxQuantity = this.maxQuantity;

    //   for (let quantity = 0; quantity <= maxQuantity; quantity++) {
    //     list.push(quantity);
    //   }
    //   return list;
    // },
    addonImage() {
      let { tags } = this.ticket;
      // spectator_img, parking_img
      if (tags?.includes("SPECTATOR"))
        return "https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/checkout/spectator.jpg";
      if (tags?.includes("PARKING"))
        return "https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/checkout/parking.jpg";
      if (tags?.includes("PHOTO PACKAGE"))
        return "https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/checkout/photo_package.jpg";
      if (tags?.includes("EU SHIRT"))
        return "https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/checkout/eu_shirt.jpg";
      if (tags?.includes("SIZE"))
        return "https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/checkout/small_t.png";

      return "https://delphi-production-images-normal-perch.s3.us-west-2.amazonaws.com/checkout/engraving.jpg";
    },
    showModalActivator() {
      return this.ticket.moreInfoContents;
    },
    disableIncrement() {
      return this.ticket.selectedQuantity === this.maxQuantity;
    },
    totalQuantity() {
      return sumBy(this.ticket.questionAnswers, "selectedQuantity");
    },
  },
  watch: {
    "ticket.selectedQuantity": {
      handler(quantity, oldQuantity) {
        if (oldQuantity === 0 && quantity === 1 && !this.ticket.complexQuestion)
          return;
        if (quantity > this.maxQuantity) return;

        this.changeQuantity(quantity);
      },
    },
    maxQuantity: {
      handler(quantity) {
        if (quantity === 0) return;
        if (this.ticket.selectedQuantity <= quantity) return;

        this.ticket.selectedQuantity = quantity;

        if (!this.ticket.complexQuestion) return;
        // Update answers for complex questions
        let removedItems = slice(this.ticket.memAnswers, quantity);
        forEachRight(removedItems, (item) => {
          // Update removed answer quantity
          let answer = find(this.ticket.questionAnswers, { id: item.id });
          if (answer.selectedQuantity > 0) answer.selectedQuantity--;
          // Update dialog answers selectors
          let removedItemIndex = findLastIndex(this.ticket.memAnswers, {
            id: item.id,
          });
          this.ticket.memAnswers.splice(removedItemIndex, 1);
        });
      },
    },
  },
  methods: {
    ...mapActions(["updateTicketsList", "updateTicketsListItem"]),
    addItem() {
      this.$emit("on-add-item", {
        payload: this.ticketPayload,
        callback: () => {
          this.ticket.selectedQuantity = 1;
        },
      });
    },
    async changeQuantity(quantity) {
      // decrease
      // increase
      let { eventId, id } = this.ticket;

      let payload = {
        ticket: {
          ts_event_id: eventId,
          ts_ticket_type_id: id,
          country: this.checkoutRegion.toUpperCase(),
        },
        quantity,
      };

      this.$emit("on-change-quantity", { payload });
    },
    onDialogConfirm({ answers, selectedAnswers }) {
      // console.log("Confirm", answers, selectedAnswers);
      // fill answers array
      this.ticket.questionAnswers = answers;
      this.ticket.memAnswers = selectedAnswers;
      this.ticket.selectedQuantity = selectedAnswers.length;
      this.showQuantityDialog = false;
    },
  },
  mounted() {
    // if (this.ticket.complexQuestion) console.log(this.ticket);
  },
};
</script>
<style lang="scss" scoped>
$mobile-view: 1024px;
.addon-card {
  height: 120px;
  border-radius: 10px;
  border: 1px solid #d8d8d8;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  padding: 16px;
  position: relative;
  overflow: hidden;
  .addon-image {
    height: 120px;
    width: 120px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
  }
  .addon-info {
    position: relative;
    z-index: 2;
    .addon-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 16px;
      line-height: 20px;
      text-align: end;
      .material-icons {
        margin-left: 4px;
        color: #a7a7a7;
        font-size: 16px;
        @include cursorPointer;
      }
    }
    .addon-price {
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
      text-align: end;
    }
  }

  .add-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background-color: #e9e9e9;
    @include cursorPointer;
    &:hover {
      background-color: #cc092f;
      color: #ffffff;
    }
  }
  .quantity-selectors {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #eaeaea;
    border-radius: 32px;
    font-weight: 700;
    .add-on-count {
      pointer-events: none;
    }
    .selector-button {
      height: 36px;
      width: 36px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      @include cursorPointer;
      &.is-disabled {
        pointer-events: none;
        .material-icons {
          color: #909090;
        }
      }
    }
  }
  .dialog-activator {
    @include cursorPointer;
  }
  // .quantity-select {
  //   height: 36px;
  //   width: 72px;
  //   border: 1px solid #d3d3d3;
  //   border-radius: 100px;
  //   font-size: 16px;
  //   font-weight: 700;
  //   line-height: 20px;

  //   display: grid;
  //   grid-template-columns: 1fr;

  //   position: relative;
  //   select {
  //     outline: none;
  //     padding-left: 12px;
  //     padding-right: 6px;
  //     opacity: 0;
  //   }
  //   .select-value {
  //     position: absolute;
  //     left: 16px;
  //     top: 50%;
  //     transform: translate(0, -50%);
  //     pointer-events: none;
  //   }
  //   i {
  //     position: absolute;
  //     right: 4px;
  //     top: 50%;
  //     transform: translate(0, -50%);
  //     pointer-events: none;
  //   }
  // }
}
.addon-modal {
  background-color: #ffffff;
  display: flex;
  padding: 24px 36px 24px 24px;
  position: relative;
  color: #222;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 12px;
  .material-icons {
    position: absolute;
    top: 12px;
    right: 12px;
    color: #d3d3d3;
    @include cursorPointer;
  }
}
</style>